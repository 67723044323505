.eye-solid.icon {
  color: #000;
  /* position: absolute; */
  margin-left: 3px;
  margin-top: 3px;
  width: 12px;
  height: 12px;
  border-radius: 70% 15%;
  border: solid 1px currentColor;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.eye-solid.icon:before {
  content: '';
  position: absolute;
  left: 2px;
  top: 2px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: solid 1px currentColor;
  background-color: currentColor;
}

